import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoginService } from '../../user/service/login.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthErrorService {
  constructor(
    private readonly toastCtrl: ToastController,
    private readonly loginService: LoginService,
  ) {}

  init() {
    if (this.has()) {
      this.clear();
      this.showAuthErrorToast();
    }
  }

  set() {
    localStorage.setItem('auth-error', '1');
  }

  private has() {
    return localStorage.getItem('auth-error') === '1';
  }

  private clear() {
    localStorage.removeItem('auth-error');
  }

  private async showAuthErrorToast(): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: 'Deine Session ist abgelaufen',
      buttons: [
        {
          role: 'cancel',
          icon: 'close',
        },
      ],
    });
    await toast.present();
    await firstValueFrom(this.loginService.login);
    toast.dismiss();
  }
}
