/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampTripLocationDto } from '../models/camp-trip-location-dto';
import { CampTripLocationDto2 } from '../models/camp-trip-location-dto-2';
import { CampTripLocationPaginateResponse } from '../models/camp-trip-location-paginate-response';
import { CampTripLocationShowResponse } from '../models/camp-trip-location-show-response';

@Injectable({
  providedIn: 'root',
})
export class CampTripLocationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampTripLocationIndex
   */
  static readonly GetCampTripLocationIndexPath = '/v1/camp-trip-location';

  /**
   * list locations.
   *
   * Fetch paginated list of camp-trip-locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripLocationIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'type' | 'priceDeparture' | 'priceReturn';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'type' | 'priceDeparture' | 'priceReturn';

    /**
     * Filter CampTripLocation id
     */
    id?: number;

    /**
     * Filter CampTrip id
     */
    trip?: number;

    /**
     * Filter camp id
     */
    camp?: number;

    /**
     * Filter CampProgram id
     */
    program?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripLocationPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripLocationService.GetCampTripLocationIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('trip', params.trip, {});
      rb.query('camp', params.camp, {});
      rb.query('program', params.program, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripLocationPaginateResponse>;
      })
    );
  }

  /**
   * list locations.
   *
   * Fetch paginated list of camp-trip-locations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripLocationIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'type' | 'priceDeparture' | 'priceReturn';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'type' | 'priceDeparture' | 'priceReturn';

    /**
     * Filter CampTripLocation id
     */
    id?: number;

    /**
     * Filter CampTrip id
     */
    trip?: number;

    /**
     * Filter camp id
     */
    camp?: number;

    /**
     * Filter CampProgram id
     */
    program?: number;
  },
  context?: HttpContext

): Observable<CampTripLocationPaginateResponse> {

    return this.getCampTripLocationIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripLocationPaginateResponse>) => r.body as CampTripLocationPaginateResponse)
    );
  }

  /**
   * Path part for operation postCampTripLocationCreate
   */
  static readonly PostCampTripLocationCreatePath = '/v1/camp-trip-location';

  /**
   * create a new location.
   *
   * create a new location connected to an exisiting CampTrip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripLocationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripLocationCreate$Response(params: {
    body: CampTripLocationDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripLocationService.PostCampTripLocationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripLocationShowResponse>;
      })
    );
  }

  /**
   * create a new location.
   *
   * create a new location connected to an exisiting CampTrip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripLocationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripLocationCreate(params: {
    body: CampTripLocationDto
  },
  context?: HttpContext

): Observable<CampTripLocationShowResponse> {

    return this.postCampTripLocationCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>) => r.body as CampTripLocationShowResponse)
    );
  }

  /**
   * Path part for operation getCampTripLocationShow
   */
  static readonly GetCampTripLocationShowPath = '/v1/camp-trip-location/{id}';

  /**
   * show a location.
   *
   * Fetch a single camp-trip-location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripLocationShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripLocationService.GetCampTripLocationShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripLocationShowResponse>;
      })
    );
  }

  /**
   * show a location.
   *
   * Fetch a single camp-trip-location
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripLocationShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripLocationShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CampTripLocationShowResponse> {

    return this.getCampTripLocationShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>) => r.body as CampTripLocationShowResponse)
    );
  }

  /**
   * Path part for operation deleteCampTripLocationRemove
   */
  static readonly DeleteCampTripLocationRemovePath = '/v1/camp-trip-location/{id}';

  /**
   * remove a location.
   *
   * mark location as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampTripLocationRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripLocationRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripLocationService.DeleteCampTripLocationRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripLocationShowResponse>;
      })
    );
  }

  /**
   * remove a location.
   *
   * mark location as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampTripLocationRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripLocationRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CampTripLocationShowResponse> {

    return this.deleteCampTripLocationRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>) => r.body as CampTripLocationShowResponse)
    );
  }

  /**
   * Path part for operation patchCampTripLocationUpdate
   */
  static readonly PatchCampTripLocationUpdatePath = '/v1/camp-trip-location/{id}';

  /**
   * update a location.
   *
   * update loaction details but not the connected trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampTripLocationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripLocationUpdate$Response(params: {
    id: string;
    body: CampTripLocationDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripLocationShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripLocationService.PatchCampTripLocationUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripLocationShowResponse>;
      })
    );
  }

  /**
   * update a location.
   *
   * update loaction details but not the connected trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampTripLocationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripLocationUpdate(params: {
    id: string;
    body: CampTripLocationDto2
  },
  context?: HttpContext

): Observable<CampTripLocationShowResponse> {

    return this.patchCampTripLocationUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripLocationShowResponse>) => r.body as CampTripLocationShowResponse)
    );
  }

}
