import { Component } from '@angular/core';
import { TokenService } from '../../../service/token.service';
import { IonicModule, LoadingController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule],
})
export class SidebarComponent {
  constructor(
    private loadingCtrl: LoadingController,
    private tokenService: TokenService,
  ) {}

  async logout() {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.tokenService.clearToken();
    location.reload();
  }
}
