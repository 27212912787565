/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ImageDto } from '../models/image-dto';
import { ImageDto2 } from '../models/image-dto-2';
import { ImagePaginateResponse } from '../models/image-paginate-response';
import { ImageShowResponse } from '../models/image-show-response';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getImageIndex
   */
  static readonly GetImageIndexPath = '/v1/image';

  /**
   * list images.
   *
   * Fetch a paginated list of images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'createdAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'createdAt';

    /**
     * Filter by image alt text
     */
    alt?: string;

    /**
     * Filter by image description
     */
    description?: string;

    /**
     * Filter by gallery name
     */
    gallery?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImagePaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImageService.GetImageIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('alt', params.alt, {});
      rb.query('description', params.description, {});
      rb.query('gallery', params.gallery, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImagePaginateResponse>;
      })
    );
  }

  /**
   * list images.
   *
   * Fetch a paginated list of images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'createdAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'createdAt';

    /**
     * Filter by image alt text
     */
    alt?: string;

    /**
     * Filter by image description
     */
    description?: string;

    /**
     * Filter by gallery name
     */
    gallery?: string;
  },
  context?: HttpContext

): Observable<ImagePaginateResponse> {

    return this.getImageIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImagePaginateResponse>) => r.body as ImagePaginateResponse)
    );
  }

  /**
   * Path part for operation postImageCreate
   */
  static readonly PostImageCreatePath = '/v1/image';

  /**
   * store a new image.
   *
   * Create a new image & upload it to the cdn if no `cdnBasePath` is provided.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postImageCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postImageCreate$Response(params: {
    body: ImageDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImageShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImageService.PostImageCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImageShowResponse>;
      })
    );
  }

  /**
   * store a new image.
   *
   * Create a new image & upload it to the cdn if no `cdnBasePath` is provided.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postImageCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postImageCreate(params: {
    body: ImageDto
  },
  context?: HttpContext

): Observable<ImageShowResponse> {

    return this.postImageCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>) => r.body as ImageShowResponse)
    );
  }

  /**
   * Path part for operation getImageShow
   */
  static readonly GetImageShowPath = '/v1/image/{id}';

  /**
   * show a image.
   *
   * Fetch a single image with contacts and addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImageShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImageService.GetImageShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImageShowResponse>;
      })
    );
  }

  /**
   * show a image.
   *
   * Fetch a single image with contacts and addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ImageShowResponse> {

    return this.getImageShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>) => r.body as ImageShowResponse)
    );
  }

  /**
   * Path part for operation deleteImageRemove
   */
  static readonly DeleteImageRemovePath = '/v1/image/{id}';

  /**
   * remove an image.
   *
   * Mark image as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImageShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImageService.DeleteImageRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImageShowResponse>;
      })
    );
  }

  /**
   * remove an image.
   *
   * Mark image as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ImageShowResponse> {

    return this.deleteImageRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>) => r.body as ImageShowResponse)
    );
  }

  /**
   * Path part for operation patchImageUpdate
   */
  static readonly PatchImageUpdatePath = '/v1/image/{id}';

  /**
   * update an image.
   *
   * update image & upload to cdn if necessary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchImageUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchImageUpdate$Response(params: {
    id: string;
    body: ImageDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImageShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImageService.PatchImageUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImageShowResponse>;
      })
    );
  }

  /**
   * update an image.
   *
   * update image & upload to cdn if necessary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchImageUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchImageUpdate(params: {
    id: string;
    body: ImageDto2
  },
  context?: HttpContext

): Observable<ImageShowResponse> {

    return this.patchImageUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>) => r.body as ImageShowResponse)
    );
  }

}
