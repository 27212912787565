import { Injectable, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TokenService } from '../../shared/service/token.service';
import { AuthService, LoginStoreResponse } from '@kidfl/openapi';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  login = new EventEmitter<LoginStoreResponse>();

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
  ) {}

  async sendLogin(username, password): Promise<LoginStoreResponse> {
    return await this.authService
      .postLogin({ body: { username, password } })
      .pipe(
        tap((r) => this.tokenService.setToken(r.token)),
        tap((r) => this.login.emit(r)),
      )
      .toPromise();
  }
}
