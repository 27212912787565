import { inject, Injectable } from '@angular/core';
import {
  HttpEvent as NgHttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { filter, Observable, tap } from 'rxjs';
import { HttpEventService } from '../service/http-event.service';

/**
 * This interceptor catches all http calls and lets the application subscribe to them
 */
@Injectable()
export class HttpEventInterceptor implements HttpInterceptor {
  private httpEventService = inject(HttpEventService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<NgHttpEvent<unknown>> {
    return next.handle(req).pipe(
      filter((httpEvent) => httpEvent instanceof HttpResponse),
      tap((res: HttpResponse<unknown>) => this.httpEventService['event$'].emit({ res, req })),
    );
  }
}
