import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '../service/token.service';
import { catchError } from 'rxjs/operators';
import { AuthErrorService } from '../service/auth-error.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly tokenService: TokenService,
    private readonly authErrorService: AuthErrorService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenService.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokenService.getToken()}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.tokenService.clearToken();
          this.authErrorService.set();
          window.location.href = '/login';
        }
        return throwError(() => error);
      }),
    );
  }
}
