/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GalleryDto } from '../models/gallery-dto';
import { GalleryDto2 } from '../models/gallery-dto-2';
import { GalleryItemDto } from '../models/gallery-item-dto';
import { GalleryPaginateResponse } from '../models/gallery-paginate-response';
import { GalleryShowResponse } from '../models/gallery-show-response';
import { GalleryShowResponse2 } from '../models/gallery-show-response-2';

@Injectable({
  providedIn: 'root',
})
export class GalleryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getGalleryIndex
   */
  static readonly GetGalleryIndexPath = '/v1/gallery';

  /**
   * list image galleries.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGalleryIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'createdAt' | 'updatedAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'createdAt' | 'updatedAt';

    /**
     * Filter by a single camp name
     */
    camp?: string;

    /**
     * Filter by a gallery name
     */
    name?: string;

    /**
     * Filter by gallery label
     */
    label?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GalleryPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.GetGalleryIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('camp', params.camp, {});
      rb.query('name', params.name, {});
      rb.query('label', params.label, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryPaginateResponse>;
      })
    );
  }

  /**
   * list image galleries.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGalleryIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'createdAt' | 'updatedAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'createdAt' | 'updatedAt';

    /**
     * Filter by a single camp name
     */
    camp?: string;

    /**
     * Filter by a gallery name
     */
    name?: string;

    /**
     * Filter by gallery label
     */
    label?: number;
  },
  context?: HttpContext

): Observable<GalleryPaginateResponse> {

    return this.getGalleryIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<GalleryPaginateResponse>) => r.body as GalleryPaginateResponse)
    );
  }

  /**
   * Path part for operation postGalleryCreate
   */
  static readonly PostGalleryCreatePath = '/v1/gallery';

  /**
   * store a new gallery.
   *
   * create a new empty gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGalleryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryCreate$Response(params: {
    body: GalleryDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GalleryShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.PostGalleryCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryShowResponse>;
      })
    );
  }

  /**
   * store a new gallery.
   *
   * create a new empty gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postGalleryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryCreate(params: {
    body: GalleryDto
  },
  context?: HttpContext

): Observable<GalleryShowResponse> {

    return this.postGalleryCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse>) => r.body as GalleryShowResponse)
    );
  }

  /**
   * Path part for operation getGalleryShow
   */
  static readonly GetGalleryShowPath = '/v1/gallery/{name}';

  /**
   * show a gallery.
   *
   * Fetch a single image gallery with images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGalleryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryShow$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GalleryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.GetGalleryShowPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryShowResponse2>;
      })
    );
  }

  /**
   * show a gallery.
   *
   * Fetch a single image gallery with images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGalleryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryShow(params: {
    name: string;
  },
  context?: HttpContext

): Observable<GalleryShowResponse2> {

    return this.getGalleryShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>) => r.body as GalleryShowResponse2)
    );
  }

  /**
   * Path part for operation deleteGalleryRemove
   */
  static readonly DeleteGalleryRemovePath = '/v1/gallery/{name}';

  /**
   * remove a gallery.
   *
   * Mark a gallery as removed. Images will persist.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGalleryRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryRemove$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GalleryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.DeleteGalleryRemovePath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryShowResponse2>;
      })
    );
  }

  /**
   * remove a gallery.
   *
   * Mark a gallery as removed. Images will persist.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGalleryRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryRemove(params: {
    name: string;
  },
  context?: HttpContext

): Observable<GalleryShowResponse2> {

    return this.deleteGalleryRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>) => r.body as GalleryShowResponse2)
    );
  }

  /**
   * Path part for operation patchGalleryUpdate
   */
  static readonly PatchGalleryUpdatePath = '/v1/gallery/{name}';

  /**
   * update a gallery.
   *
   * Update gallery details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchGalleryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchGalleryUpdate$Response(params: {
    name: string;
    body: GalleryDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GalleryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.PatchGalleryUpdatePath, 'patch');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GalleryShowResponse2>;
      })
    );
  }

  /**
   * update a gallery.
   *
   * Update gallery details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchGalleryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchGalleryUpdate(params: {
    name: string;
    body: GalleryDto2
  },
  context?: HttpContext

): Observable<GalleryShowResponse2> {

    return this.patchGalleryUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>) => r.body as GalleryShowResponse2)
    );
  }

  /**
   * Path part for operation postGalleryItemCreate
   */
  static readonly PostGalleryItemCreatePath = '/v1/gallery/{name}/item';

  /**
   * store a new gallery-item.
   *
   * add item to a gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGalleryItemCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryItemCreate$Response(params: {
    name: string;
    body: GalleryItemDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.PostGalleryItemCreatePath, 'post');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * store a new gallery-item.
   *
   * add item to a gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postGalleryItemCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryItemCreate(params: {
    name: string;
    body: GalleryItemDto
  },
  context?: HttpContext

): Observable<void> {

    return this.postGalleryItemCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteGalleryItemRemove
   */
  static readonly DeleteGalleryItemRemovePath = '/v1/gallery/{name}/item/{id}';

  /**
   * remove a gallery-item.
   *
   * Remove an item from a gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGalleryItemRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryItemRemove$Response(params: {
    name: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GalleryService.DeleteGalleryItemRemovePath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * remove a gallery-item.
   *
   * Remove an item from a gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGalleryItemRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryItemRemove(params: {
    name: string;
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteGalleryItemRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
