/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DiscountCampaignDto } from '../models/discount-campaign-dto';
import { DiscountCampaignPaginateResponse } from '../models/discount-campaign-paginate-response';
import { DiscountCampaignShowResponse } from '../models/discount-campaign-show-response';
import { DiscountCampaignShowResponse2 } from '../models/discount-campaign-show-response-2';
import { DiscountCouponDto } from '../models/discount-coupon-dto';
import { DiscountCouponPaginateResponse } from '../models/discount-coupon-paginate-response';
import { DiscountCouponShowResponse } from '../models/discount-coupon-show-response';

@Injectable({
  providedIn: 'root',
})
export class DiscountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDiscountIndex
   */
  static readonly GetDiscountIndexPath = '/v1/discount';

  /**
   * list discounts.
   *
   * Fetch paginated list of discounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'value' | 'type' | 'active';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'value' | 'type' | 'active';

    /**
     * Filter DiscountCampaign id
     */
    id?: number;

    /**
     * Filter by camp id
     */
    camp?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCampaignPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.GetDiscountIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('camp', params.camp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCampaignPaginateResponse>;
      })
    );
  }

  /**
   * list discounts.
   *
   * Fetch paginated list of discounts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'value' | 'type' | 'active';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'value' | 'type' | 'active';

    /**
     * Filter DiscountCampaign id
     */
    id?: number;

    /**
     * Filter by camp id
     */
    camp?: number;
  },
  context?: HttpContext

): Observable<DiscountCampaignPaginateResponse> {

    return this.getDiscountIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignPaginateResponse>) => r.body as DiscountCampaignPaginateResponse)
    );
  }

  /**
   * Path part for operation postDiscountCreate
   */
  static readonly PostDiscountCreatePath = '/v1/discount';

  /**
   * store a new discount.
   *
   * Add a new discount campaign
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDiscountCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCreate$Response(params: {
    body: DiscountCampaignDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.PostDiscountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCampaignShowResponse>;
      })
    );
  }

  /**
   * store a new discount.
   *
   * Add a new discount campaign
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDiscountCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCreate(params: {
    body: DiscountCampaignDto
  },
  context?: HttpContext

): Observable<DiscountCampaignShowResponse> {

    return this.postDiscountCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>) => r.body as DiscountCampaignShowResponse)
    );
  }

  /**
   * Path part for operation getDiscountShow
   */
  static readonly GetDiscountShowPath = '/v1/discount/{id}';

  /**
   * show a discount.
   *
   * Fetch a single discount campaign
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.GetDiscountShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCampaignShowResponse>;
      })
    );
  }

  /**
   * show a discount.
   *
   * Fetch a single discount campaign
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<DiscountCampaignShowResponse> {

    return this.getDiscountShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>) => r.body as DiscountCampaignShowResponse)
    );
  }

  /**
   * Path part for operation deleteDiscountRemove
   */
  static readonly DeleteDiscountRemovePath = '/v1/discount/{id}';

  /**
   * remove a discount.
   *
   * mark discount as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDiscountRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.DeleteDiscountRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCampaignShowResponse>;
      })
    );
  }

  /**
   * remove a discount.
   *
   * mark discount as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDiscountRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<DiscountCampaignShowResponse> {

    return this.deleteDiscountRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>) => r.body as DiscountCampaignShowResponse)
    );
  }

  /**
   * Path part for operation patchDiscountUpdate
   */
  static readonly PatchDiscountUpdatePath = '/v1/discount/{id}';

  /**
   * update a discount.
   *
   * update discount details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDiscountUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscountUpdate$Response(params: {
    id: string;
    body: DiscountCampaignShowResponse2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.PatchDiscountUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCampaignShowResponse>;
      })
    );
  }

  /**
   * update a discount.
   *
   * update discount details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDiscountUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscountUpdate(params: {
    id: string;
    body: DiscountCampaignShowResponse2
  },
  context?: HttpContext

): Observable<DiscountCampaignShowResponse> {

    return this.patchDiscountUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>) => r.body as DiscountCampaignShowResponse)
    );
  }

  /**
   * Path part for operation postCampDiscountCreate
   */
  static readonly PostCampDiscountCreatePath = '/v1/camp/{camp}/discount/{id}';

  /**
   * add discount to camp.
   *
   * Add a an existing discount-campaign to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampDiscountCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCampDiscountCreate$Response(params: {
    camp: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.PostCampDiscountCreatePath, 'post');
    if (params) {
      rb.path('camp', params.camp, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * add discount to camp.
   *
   * Add a an existing discount-campaign to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampDiscountCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCampDiscountCreate(params: {
    camp: string;
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.postCampDiscountCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCampDiscountRemove
   */
  static readonly DeleteCampDiscountRemovePath = '/v1/camp/{camp}/discount/{id}';

  /**
   * remove discount from camp.
   *
   * Remove discount-campaign from a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampDiscountRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampDiscountRemove$Response(params: {
    camp: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.DeleteCampDiscountRemovePath, 'delete');
    if (params) {
      rb.path('camp', params.camp, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * remove discount from camp.
   *
   * Remove discount-campaign from a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampDiscountRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampDiscountRemove(params: {
    camp: string;
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCampDiscountRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDiscountCouponIndex
   */
  static readonly GetDiscountCouponIndexPath = '/v1/discount-coupon';

  /**
   * list coupons.
   *
   * Fetch paginated list of discount coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountCouponIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'value' | 'reusable' | 'isUsed' | 'expire' | 'createdAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'value' | 'reusable' | 'isUsed' | 'expire' | 'createdAt';

    /**
     * Filter DiscountCoupon id
     */
    id?: number;

    /**
     * Filter DiscountCampaign id
     */
    discountCampaign?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCouponPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.GetDiscountCouponIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('discountCampaign', params.discountCampaign, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCouponPaginateResponse>;
      })
    );
  }

  /**
   * list coupons.
   *
   * Fetch paginated list of discount coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountCouponIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'value' | 'reusable' | 'isUsed' | 'expire' | 'createdAt';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'value' | 'reusable' | 'isUsed' | 'expire' | 'createdAt';

    /**
     * Filter DiscountCoupon id
     */
    id?: number;

    /**
     * Filter DiscountCampaign id
     */
    discountCampaign?: number;
  },
  context?: HttpContext

): Observable<DiscountCouponPaginateResponse> {

    return this.getDiscountCouponIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCouponPaginateResponse>) => r.body as DiscountCouponPaginateResponse)
    );
  }

  /**
   * Path part for operation postDiscountCouponCreate
   */
  static readonly PostDiscountCouponCreatePath = '/v1/discount-coupon';

  /**
   * store multiple new coupons.
   *
   * Add multiple new coupons to an existing campaign.
   * Use `allowedCharacters` and `length` to customize the coupon value.
   * Use `amount` to define the total number of coupons to create.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDiscountCouponCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCouponCreate$Response(params: {
    body: DiscountCouponDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCouponPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.PostDiscountCouponCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCouponPaginateResponse>;
      })
    );
  }

  /**
   * store multiple new coupons.
   *
   * Add multiple new coupons to an existing campaign.
   * Use `allowedCharacters` and `length` to customize the coupon value.
   * Use `amount` to define the total number of coupons to create.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDiscountCouponCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCouponCreate(params: {
    body: DiscountCouponDto
  },
  context?: HttpContext

): Observable<DiscountCouponPaginateResponse> {

    return this.postDiscountCouponCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCouponPaginateResponse>) => r.body as DiscountCouponPaginateResponse)
    );
  }

  /**
   * Path part for operation getDiscountCouponShow
   */
  static readonly GetDiscountCouponShowPath = '/v1/camp/{camp}/discount-coupon/{value}';

  /**
   * show a coupon.
   *
   * Fetch a single unused DiscountCoupon by value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountCouponShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponShow$Response(params: {
    camp: string;
    value: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCouponShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.GetDiscountCouponShowPath, 'get');
    if (params) {
      rb.path('camp', params.camp, {});
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCouponShowResponse>;
      })
    );
  }

  /**
   * show a coupon.
   *
   * Fetch a single unused DiscountCoupon by value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountCouponShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponShow(params: {
    camp: string;
    value: string;
  },
  context?: HttpContext

): Observable<DiscountCouponShowResponse> {

    return this.getDiscountCouponShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCouponShowResponse>) => r.body as DiscountCouponShowResponse)
    );
  }

  /**
   * Path part for operation deleteDiscountCouponRemove
   */
  static readonly DeleteDiscountCouponRemovePath = '/v1/discount-coupon/{value}';

  /**
   * remove a coupon.
   *
   * mark coupon as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDiscountCouponRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountCouponRemove$Response(params: {
    value: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DiscountCouponShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DiscountService.DeleteDiscountCouponRemovePath, 'delete');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DiscountCouponShowResponse>;
      })
    );
  }

  /**
   * remove a coupon.
   *
   * mark coupon as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDiscountCouponRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountCouponRemove(params: {
    value: string;
  },
  context?: HttpContext

): Observable<DiscountCouponShowResponse> {

    return this.deleteDiscountCouponRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<DiscountCouponShowResponse>) => r.body as DiscountCouponShowResponse)
    );
  }

}
