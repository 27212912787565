/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampCategoryCampDto } from '../models/camp-category-camp-dto';
import { CampCategoryCampDto2 } from '../models/camp-category-camp-dto-2';
import { CampCategoryCampListResponse } from '../models/camp-category-camp-list-response';
import { CampCategoryCampShowResponse } from '../models/camp-category-camp-show-response';
import { CampDto } from '../models/camp-dto';
import { CampDto2 } from '../models/camp-dto-2';
import { CampPaginateResponse } from '../models/camp-paginate-response';
import { CampShowResponse } from '../models/camp-show-response';
import { CampShowResponse2 } from '../models/camp-show-response-2';

@Injectable({
  providedIn: 'root',
})
export class CampService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampCategoryCampIndex
   */
  static readonly GetCampCategoryCampIndexPath = '/v1/category/{category}/camp';

  /**
   * list camps sorted by priority.
   *
   * Get a not-paginated list of camps, ordered by priority in that specific category.
   * This is used for the card view in the category pages like [/ferienlager/sommer](https://kinder-ferienlager.com/ferienlager/sommer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryCampIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryCampIndex$Response(params: {

    /**
     * CampCategory name
     */
    category: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryCampListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.GetCampCategoryCampIndexPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryCampListResponse>;
      })
    );
  }

  /**
   * list camps sorted by priority.
   *
   * Get a not-paginated list of camps, ordered by priority in that specific category.
   * This is used for the card view in the category pages like [/ferienlager/sommer](https://kinder-ferienlager.com/ferienlager/sommer)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryCampIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryCampIndex(params: {

    /**
     * CampCategory name
     */
    category: string;
  },
  context?: HttpContext

): Observable<CampCategoryCampListResponse> {

    return this.getCampCategoryCampIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampListResponse>) => r.body as CampCategoryCampListResponse)
    );
  }

  /**
   * Path part for operation postCampCategoryCampCreate
   */
  static readonly PostCampCategoryCampCreatePath = '/v1/category/{category}/camp/{camp}';

  /**
   * Add a category to a camp.
   *
   * Connect a category to a camp with a set priority
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCategoryCampCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCampCreate$Response(params: {
    category: string;
    camp: string;
    body: CampCategoryCampDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.PostCampCategoryCampCreatePath, 'post');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('camp', params.camp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryCampShowResponse>;
      })
    );
  }

  /**
   * Add a category to a camp.
   *
   * Connect a category to a camp with a set priority
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCategoryCampCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCampCreate(params: {
    category: string;
    camp: string;
    body: CampCategoryCampDto
  },
  context?: HttpContext

): Observable<CampCategoryCampShowResponse> {

    return this.postCampCategoryCampCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>) => r.body as CampCategoryCampShowResponse)
    );
  }

  /**
   * Path part for operation deleteCampCategoryCampRemove
   */
  static readonly DeleteCampCategoryCampRemovePath = '/v1/category/{category}/camp/{camp}';

  /**
   * remove a category from a camp.
   *
   * Remove a category reference from a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampCategoryCampRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryCampRemove$Response(params: {
    category: string;
    camp: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.DeleteCampCategoryCampRemovePath, 'delete');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('camp', params.camp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryCampShowResponse>;
      })
    );
  }

  /**
   * remove a category from a camp.
   *
   * Remove a category reference from a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampCategoryCampRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryCampRemove(params: {
    category: string;
    camp: string;
  },
  context?: HttpContext

): Observable<CampCategoryCampShowResponse> {

    return this.deleteCampCategoryCampRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>) => r.body as CampCategoryCampShowResponse)
    );
  }

  /**
   * Path part for operation patchCampCategoryCampUpdate
   */
  static readonly PatchCampCategoryCampUpdatePath = '/v1/category/{category}/camp/{camp}';

  /**
   * Update a category/camp connection.
   *
   * Update additional data of a Camp x Category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampCategoryCampUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryCampUpdate$Response(params: {
    category: string;
    camp: string;
    body: CampCategoryCampDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryCampShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.PatchCampCategoryCampUpdatePath, 'patch');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('camp', params.camp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryCampShowResponse>;
      })
    );
  }

  /**
   * Update a category/camp connection.
   *
   * Update additional data of a Camp x Category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampCategoryCampUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryCampUpdate(params: {
    category: string;
    camp: string;
    body: CampCategoryCampDto2
  },
  context?: HttpContext

): Observable<CampCategoryCampShowResponse> {

    return this.patchCampCategoryCampUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryCampShowResponse>) => r.body as CampCategoryCampShowResponse)
    );
  }

  /**
   * Path part for operation getCampIndex
   */
  static readonly GetCampIndexPath = '/v1/camp';

  /**
   * list camps.
   *
   * Fetch paginated list of camps with base data to display links
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort';

    /**
     * Filter camp id
     */
    id?: number;

    /**
     * Filter category id, comma separated values possible
     */
    category?: string;

    /**
     * Filter gallery id, comma separated values possible
     */
    gallery?: string;

    /**
     * Filter discountCampaign id, comma separated values not possible
     */
    discountCampaign?: string;

    /**
     * Filter by name
     */
    name?: string;

    /**
     * Filter by label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.GetCampIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('category', params.category, {});
      rb.query('gallery', params.gallery, {});
      rb.query('discountCampaign', params.discountCampaign, {});
      rb.query('name', params.name, {});
      rb.query('label', params.label, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampPaginateResponse>;
      })
    );
  }

  /**
   * list camps.
   *
   * Fetch paginated list of camps with base data to display links
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort';

    /**
     * Filter camp id
     */
    id?: number;

    /**
     * Filter category id, comma separated values possible
     */
    category?: string;

    /**
     * Filter gallery id, comma separated values possible
     */
    gallery?: string;

    /**
     * Filter discountCampaign id, comma separated values not possible
     */
    discountCampaign?: string;

    /**
     * Filter by name
     */
    name?: string;

    /**
     * Filter by label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<CampPaginateResponse> {

    return this.getCampIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampPaginateResponse>) => r.body as CampPaginateResponse)
    );
  }

  /**
   * Path part for operation postCampCreate
   */
  static readonly PostCampCreatePath = '/v1/camp';

  /**
   * add a new camp.
   *
   * create a new camp with image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCreate$Response(params: {
    body: CampDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.PostCampCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampShowResponse>;
      })
    );
  }

  /**
   * add a new camp.
   *
   * create a new camp with image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCreate(params: {
    body: CampDto
  },
  context?: HttpContext

): Observable<CampShowResponse> {

    return this.postCampCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampShowResponse>) => r.body as CampShowResponse)
    );
  }

  /**
   * Path part for operation getCampShow
   */
  static readonly GetCampShowPath = '/v1/camp/{name}';

  /**
   * show a camp.
   *
   * Fetch a single camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampShow$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.GetCampShowPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampShowResponse2>;
      })
    );
  }

  /**
   * show a camp.
   *
   * Fetch a single camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampShow(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampShowResponse2> {

    return this.getCampShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>) => r.body as CampShowResponse2)
    );
  }

  /**
   * Path part for operation deleteCampRemove
   */
  static readonly DeleteCampRemovePath = '/v1/camp/{name}';

  /**
   * remove a camp.
   *
   * Mark camp as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRemove$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.DeleteCampRemovePath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampShowResponse2>;
      })
    );
  }

  /**
   * remove a camp.
   *
   * Mark camp as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRemove(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampShowResponse2> {

    return this.deleteCampRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>) => r.body as CampShowResponse2)
    );
  }

  /**
   * Path part for operation patchCampUpdate
   */
  static readonly PatchCampUpdatePath = '/v1/camp/{name}';

  /**
   * update a camp.
   *
   * update camp and new image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampUpdate$Response(params: {
    name: string;
    body: CampDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampService.PatchCampUpdatePath, 'patch');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampShowResponse2>;
      })
    );
  }

  /**
   * update a camp.
   *
   * update camp and new image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampUpdate(params: {
    name: string;
    body: CampDto2
  },
  context?: HttpContext

): Observable<CampShowResponse2> {

    return this.patchCampUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampShowResponse2>) => r.body as CampShowResponse2)
    );
  }

}
