/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationDto } from '../models/location-dto';
import { LocationPaginateResponse } from '../models/location-paginate-response';
import { LocationShowResponse } from '../models/location-show-response';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLocationIndex
   */
  static readonly GetLocationIndexPath = '/v1/location';

  /**
   * list locations.
   *
   * Fetch paginated list of locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label';

    /**
     * Filter Location id
     */
    id?: number;

    /**
     * Filter Location label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LocationPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.GetLocationIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('label', params.label, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationPaginateResponse>;
      })
    );
  }

  /**
   * list locations.
   *
   * Fetch paginated list of locations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'label';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'label';

    /**
     * Filter Location id
     */
    id?: number;

    /**
     * Filter Location label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<LocationPaginateResponse> {

    return this.getLocationIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<LocationPaginateResponse>) => r.body as LocationPaginateResponse)
    );
  }

  /**
   * Path part for operation postLocationCreate
   */
  static readonly PostLocationCreatePath = '/v1/location';

  /**
   * create a new location.
   *
   * create a new location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLocationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLocationCreate$Response(params: {
    body: LocationDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LocationShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.PostLocationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationShowResponse>;
      })
    );
  }

  /**
   * create a new location.
   *
   * create a new location
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLocationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLocationCreate(params: {
    body: LocationDto
  },
  context?: HttpContext

): Observable<LocationShowResponse> {

    return this.postLocationCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<LocationShowResponse>) => r.body as LocationShowResponse)
    );
  }

}
