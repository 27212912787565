/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export { ApiConfiguration } from './api-configuration';
export { BaseService } from './base-service';
export { RequestBuilder } from './request-builder';
export { StrictHttpResponse } from './strict-http-response';
export { ApiModule } from './api.module';
export { Address } from './models/address';
export { Address2 } from './models/address-2';
export { Address3 } from './models/address-3';
export { Address4 } from './models/address-4';
export { Address5 } from './models/address-5';
export { Address6 } from './models/address-6';
export { Address7 } from './models/address-7';
export { BlogPost } from './models/blog-post';
export { BlogPost2 } from './models/blog-post-2';
export { BlogPost3 } from './models/blog-post-3';
export { BlogPost4 } from './models/blog-post-4';
export { BlogPostDto } from './models/blog-post-dto';
export { BlogPostDto2 } from './models/blog-post-dto-2';
export { BlogPostPaginateResponse } from './models/blog-post-paginate-response';
export { BlogPostShowResponse } from './models/blog-post-show-response';
export { BlogPostShowResponse2 } from './models/blog-post-show-response-2';
export { Camp } from './models/camp';
export { Camp10 } from './models/camp-10';
export { Camp11 } from './models/camp-11';
export { Camp2 } from './models/camp-2';
export { Camp3 } from './models/camp-3';
export { Camp4 } from './models/camp-4';
export { Camp5 } from './models/camp-5';
export { Camp6 } from './models/camp-6';
export { Camp7 } from './models/camp-7';
export { Camp8 } from './models/camp-8';
export { Camp9 } from './models/camp-9';
export { CampCategory } from './models/camp-category';
export { CampCategory2 } from './models/camp-category-2';
export { CampCategory3 } from './models/camp-category-3';
export { CampCategory4 } from './models/camp-category-4';
export { CampCategoryCamp } from './models/camp-category-camp';
export { CampCategoryCamp2 } from './models/camp-category-camp-2';
export { CampCategoryCamp3 } from './models/camp-category-camp-3';
export { CampCategoryCampDto } from './models/camp-category-camp-dto';
export { CampCategoryCampDto2 } from './models/camp-category-camp-dto-2';
export { CampCategoryCampListResponse } from './models/camp-category-camp-list-response';
export { CampCategoryCampShowResponse } from './models/camp-category-camp-show-response';
export { CampCategoryDto } from './models/camp-category-dto';
export { CampCategoryDto2 } from './models/camp-category-dto-2';
export { CampCategoryPaginateResponse } from './models/camp-category-paginate-response';
export { CampCategoryShowResponse } from './models/camp-category-show-response';
export { CampCategoryShowResponse2 } from './models/camp-category-show-response-2';
export { CampDto } from './models/camp-dto';
export { CampDto2 } from './models/camp-dto-2';
export { CampDto3 } from './models/camp-dto-3';
export { CampPaginateResponse } from './models/camp-paginate-response';
export { CampProgram } from './models/camp-program';
export { CampProgram2 } from './models/camp-program-2';
export { CampProgram3 } from './models/camp-program-3';
export { CampProgram4 } from './models/camp-program-4';
export { CampProgram5 } from './models/camp-program-5';
export { CampProgram6 } from './models/camp-program-6';
export { CampProgramDto } from './models/camp-program-dto';
export { CampProgramDto2 } from './models/camp-program-dto-2';
export { CampProgramPaginateResponse } from './models/camp-program-paginate-response';
export { CampProgramShowResponse } from './models/camp-program-show-response';
export { CampProgramShowResponse2 } from './models/camp-program-show-response-2';
export { CampRegion } from './models/camp-region';
export { CampRegion2 } from './models/camp-region-2';
export { CampRegion3 } from './models/camp-region-3';
export { CampRegion4 } from './models/camp-region-4';
export { CampRegionDto } from './models/camp-region-dto';
export { CampShowResponse } from './models/camp-show-response';
export { CampShowResponse2 } from './models/camp-show-response-2';
export { CampTrip } from './models/camp-trip';
export { CampTrip2 } from './models/camp-trip-2';
export { CampTrip3 } from './models/camp-trip-3';
export { CampTrip4 } from './models/camp-trip-4';
export { CampTrip5 } from './models/camp-trip-5';
export { CampTrip6 } from './models/camp-trip-6';
export { CampTripDto } from './models/camp-trip-dto';
export { CampTripDto2 } from './models/camp-trip-dto-2';
export { CampTripLocation } from './models/camp-trip-location';
export { CampTripLocation2 } from './models/camp-trip-location-2';
export { CampTripLocation3 } from './models/camp-trip-location-3';
export { CampTripLocation4 } from './models/camp-trip-location-4';
export { CampTripLocation5 } from './models/camp-trip-location-5';
export { CampTripLocation6 } from './models/camp-trip-location-6';
export { CampTripLocationDto } from './models/camp-trip-location-dto';
export { CampTripLocationDto2 } from './models/camp-trip-location-dto-2';
export { CampTripLocationPaginateResponse } from './models/camp-trip-location-paginate-response';
export { CampTripLocationShowResponse } from './models/camp-trip-location-show-response';
export { CampTripPaginateResponse } from './models/camp-trip-paginate-response';
export { CampTripShowResponse } from './models/camp-trip-show-response';
export { ChangePasswordDto } from './models/change-password-dto';
export { ChildTripFormData } from './models/child-trip-form-data';
export { ChildTripFormData2 } from './models/child-trip-form-data-2';
export { ChildTripFormData3 } from './models/child-trip-form-data-3';
export { Contact } from './models/contact';
export { Contact2 } from './models/contact-2';
export { Contact3 } from './models/contact-3';
export { Contact4 } from './models/contact-4';
export { Contact5 } from './models/contact-5';
export { Contact6 } from './models/contact-6';
export { Contact7 } from './models/contact-7';
export { Customer } from './models/customer';
export { Customer2 } from './models/customer-2';
export { Customer3 } from './models/customer-3';
export { Customer4 } from './models/customer-4';
export { Customer5 } from './models/customer-5';
export { Customer6 } from './models/customer-6';
export { Customer7 } from './models/customer-7';
export { CustomerDto } from './models/customer-dto';
export { CustomerDto2 } from './models/customer-dto-2';
export { CustomerPaginateResponse } from './models/customer-paginate-response';
export { CustomerShowResponse } from './models/customer-show-response';
export { CustomerShowResponse2 } from './models/customer-show-response-2';
export { DiscountCampaign } from './models/discount-campaign';
export { DiscountCampaign2 } from './models/discount-campaign-2';
export { DiscountCampaign3 } from './models/discount-campaign-3';
export { DiscountCampaign4 } from './models/discount-campaign-4';
export { DiscountCampaign5 } from './models/discount-campaign-5';
export { DiscountCampaignDto } from './models/discount-campaign-dto';
export { DiscountCampaignPaginateResponse } from './models/discount-campaign-paginate-response';
export { DiscountCampaignShowResponse } from './models/discount-campaign-show-response';
export { DiscountCampaignShowResponse2 } from './models/discount-campaign-show-response-2';
export { DiscountCoupon } from './models/discount-coupon';
export { DiscountCoupon2 } from './models/discount-coupon-2';
export { DiscountCoupon3 } from './models/discount-coupon-3';
export { DiscountCoupon4 } from './models/discount-coupon-4';
export { DiscountCouponDto } from './models/discount-coupon-dto';
export { DiscountCouponPaginateResponse } from './models/discount-coupon-paginate-response';
export { DiscountCouponShowResponse } from './models/discount-coupon-show-response';
export { EmergencyContact } from './models/emergency-contact';
export { EmergencyContact2 } from './models/emergency-contact-2';
export { ErrorResponse } from './models/error-response';
export { Faq } from './models/faq';
export { Faq2 } from './models/faq-2';
export { Faq3 } from './models/faq-3';
export { Faq4 } from './models/faq-4';
export { FaqCategory } from './models/faq-category';
export { FaqCategory2 } from './models/faq-category-2';
export { FaqCategory3 } from './models/faq-category-3';
export { FaqCategory4 } from './models/faq-category-4';
export { FaqCategory5 } from './models/faq-category-5';
export { FaqCategory6 } from './models/faq-category-6';
export { FaqCategory7 } from './models/faq-category-7';
export { FaqCategoryDto } from './models/faq-category-dto';
export { FaqCategoryDto2 } from './models/faq-category-dto-2';
export { FaqCategoryPaginateResponse } from './models/faq-category-paginate-response';
export { FaqCategoryShowResponse } from './models/faq-category-show-response';
export { FaqDto } from './models/faq-dto';
export { FaqDto2 } from './models/faq-dto-2';
export { FaqPaginateResponse } from './models/faq-paginate-response';
export { FaqRelationDto } from './models/faq-relation-dto';
export { FaqShowResponse } from './models/faq-show-response';
export { FileStoreResponse } from './models/file-store-response';
export { Gallery } from './models/gallery';
export { Gallery2 } from './models/gallery-2';
export { Gallery3 } from './models/gallery-3';
export { Gallery4 } from './models/gallery-4';
export { Gallery5 } from './models/gallery-5';
export { GalleryDto } from './models/gallery-dto';
export { GalleryDto2 } from './models/gallery-dto-2';
export { GalleryItemDto } from './models/gallery-item-dto';
export { GalleryPaginateResponse } from './models/gallery-paginate-response';
export { GalleryShowResponse } from './models/gallery-show-response';
export { GalleryShowResponse2 } from './models/gallery-show-response-2';
export { Image } from './models/image';
export { Image10 } from './models/image-10';
export { Image11 } from './models/image-11';
export { Image12 } from './models/image-12';
export { Image2 } from './models/image-2';
export { Image3 } from './models/image-3';
export { Image4 } from './models/image-4';
export { Image5 } from './models/image-5';
export { Image6 } from './models/image-6';
export { Image7 } from './models/image-7';
export { Image8 } from './models/image-8';
export { Image9 } from './models/image-9';
export { ImageDto } from './models/image-dto';
export { ImageDto2 } from './models/image-dto-2';
export { ImagePaginateResponse } from './models/image-paginate-response';
export { ImageShowResponse } from './models/image-show-response';
export { Location } from './models/location';
export { Location2 } from './models/location-2';
export { Location3 } from './models/location-3';
export { Location4 } from './models/location-4';
export { Location5 } from './models/location-5';
export { Location6 } from './models/location-6';
export { Location7 } from './models/location-7';
export { Location8 } from './models/location-8';
export { Location9 } from './models/location-9';
export { LocationDto } from './models/location-dto';
export { LocationPaginateResponse } from './models/location-paginate-response';
export { LocationShowResponse } from './models/location-show-response';
export { LoginStoreResponse } from './models/login-store-response';
export { Order } from './models/order';
export { Order2 } from './models/order-2';
export { OrderCreateChildDto } from './models/order-create-child-dto';
export { OrderCreateDto } from './models/order-create-dto';
export { OrderCreateRequestDto } from './models/order-create-request-dto';
export { OrderPaginateResponse } from './models/order-paginate-response';
export { OrderShowResponse } from './models/order-show-response';
export { OrderStoreResponse } from './models/order-store-response';
export { Person } from './models/person';
export { Person2 } from './models/person-2';
export { Person3 } from './models/person-3';
export { Person4 } from './models/person-4';
export { Person5 } from './models/person-5';
export { Person6 } from './models/person-6';
export { Person7 } from './models/person-7';
export { RegionDto } from './models/region-dto';
export { RegionPaginateResponse } from './models/region-paginate-response';
export { RegionShowResponse } from './models/region-show-response';
export { TripProgramDto } from './models/trip-program-dto';
export { VersionResponse } from './models/version-response';
export { AuthService } from './services/auth.service';
export { BlogPostService } from './services/blog-post.service';
export { CampService } from './services/camp.service';
export { CampCategoryService } from './services/camp-category.service';
export { CampProgramService } from './services/camp-program.service';
export { CampRegionService } from './services/camp-region.service';
export { CampTripService } from './services/camp-trip.service';
export { CampTripLocationService } from './services/camp-trip-location.service';
export { CustomerService } from './services/customer.service';
export { DiscountService } from './services/discount.service';
export { FaqCategoryService } from './services/faq-category.service';
export { FaqService } from './services/faq.service';
export { FileService } from './services/file.service';
export { GalleryService } from './services/gallery.service';
export { ApiService } from './services/api.service';
export { ImageService } from './services/image.service';
export { LocationService } from './services/location.service';
export { OrderService } from './services/order.service';
export { RegionService } from './services/region.service';
