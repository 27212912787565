import { Injectable } from '@angular/core';
import { TokenService } from '../service/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard {
  constructor(private readonly tokenService: TokenService) {}

  canActivate() {
    if (!this.tokenService.getToken()) {
      window.location.href = '/login';
      return false;
    }
    return true;
  }
}
