/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VersionResponse } from '../models/version-response';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHome
   */
  static readonly GetHomePath = '/';

  /**
   * version.
   *
   * Prints the current version + meta data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHome()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHome$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetHomePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VersionResponse>;
      })
    );
  }

  /**
   * version.
   *
   * Prints the current version + meta data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHome$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHome(params?: {
  },
  context?: HttpContext

): Observable<VersionResponse> {

    return this.getHome$Response(params,context).pipe(
      map((r: StrictHttpResponse<VersionResponse>) => r.body as VersionResponse)
    );
  }

  /**
   * Path part for operation explorerOpenapiJsonGet
   */
  static readonly ExplorerOpenapiJsonGetPath = '/explorer/openapi.json';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `explorerOpenapiJsonGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  explorerOpenapiJsonGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ExplorerOpenapiJsonGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `explorerOpenapiJsonGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  explorerOpenapiJsonGet(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.explorerOpenapiJsonGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
