import { Component } from '@angular/core';
import { IonicModule, MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { SidebarComponent } from './module/shared/component/layout/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { BartComponent } from './module/shared/component/layout/bart/bart.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [IonicModule, SidebarComponent, CommonModule, BartComponent],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private menu: MenuController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.router.events.subscribe(() => {
        // remove leftover tooltips
        while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
          document.getElementsByTagName('mat-tooltip-component')[0].remove();
        }
      });
      // activate menu
      this.menu.enable(true, 'main');
    });
  }

  get debug() {
    return !environment.production;
  }
}
