/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampCategoryDto } from '../models/camp-category-dto';
import { CampCategoryDto2 } from '../models/camp-category-dto-2';
import { CampCategoryPaginateResponse } from '../models/camp-category-paginate-response';
import { CampCategoryShowResponse } from '../models/camp-category-show-response';
import { CampCategoryShowResponse2 } from '../models/camp-category-show-response-2';

@Injectable({
  providedIn: 'root',
})
export class CampCategoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampCategoryIndex
   */
  static readonly GetCampCategoryIndexPath = '/v1/camp-category';

  /**
   * list categories.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'descriptionLink' | 'priority';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'descriptionLink' | 'priority';

    /**
     * Filter category id
     */
    id?: number;

    /**
     * Filter by visible label
     */
    label?: string;

    /**
     * Filter by visible name
     */
    name?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampCategoryService.GetCampCategoryIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('label', params.label, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryPaginateResponse>;
      })
    );
  }

  /**
   * list categories.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'descriptionLink' | 'priority';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'name' | 'label' | 'description' | 'descriptionMeta' | 'descriptionLink' | 'priority';

    /**
     * Filter category id
     */
    id?: number;

    /**
     * Filter by visible label
     */
    label?: string;

    /**
     * Filter by visible name
     */
    name?: string;
  },
  context?: HttpContext

): Observable<CampCategoryPaginateResponse> {

    return this.getCampCategoryIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryPaginateResponse>) => r.body as CampCategoryPaginateResponse)
    );
  }

  /**
   * Path part for operation postCampCategoryCreate
   */
  static readonly PostCampCategoryCreatePath = '/v1/camp-category';

  /**
   * Add a new category.
   *
   * create a new gallery and image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCreate$Response(params: {
    body: CampCategoryDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampCategoryService.PostCampCategoryCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryShowResponse>;
      })
    );
  }

  /**
   * Add a new category.
   *
   * create a new gallery and image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampCategoryCreate(params: {
    body: CampCategoryDto
  },
  context?: HttpContext

): Observable<CampCategoryShowResponse> {

    return this.postCampCategoryCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse>) => r.body as CampCategoryShowResponse)
    );
  }

  /**
   * Path part for operation getCampCategoryShow
   */
  static readonly GetCampCategoryShowPath = '/v1/camp-category/{name}';

  /**
   * show a category.
   *
   * Fetch a single category with image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampCategoryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryShow$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampCategoryService.GetCampCategoryShowPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryShowResponse2>;
      })
    );
  }

  /**
   * show a category.
   *
   * Fetch a single category with image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampCategoryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampCategoryShow(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampCategoryShowResponse2> {

    return this.getCampCategoryShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>) => r.body as CampCategoryShowResponse2)
    );
  }

  /**
   * Path part for operation deleteCampCategoryRemove
   */
  static readonly DeleteCampCategoryRemovePath = '/v1/camp-category/{name}';

  /**
   * remove a category.
   *
   * Mark a category as removed. The image will persist.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampCategoryRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryRemove$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampCategoryService.DeleteCampCategoryRemovePath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryShowResponse2>;
      })
    );
  }

  /**
   * remove a category.
   *
   * Mark a category as removed. The image will persist.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampCategoryRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampCategoryRemove(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampCategoryShowResponse2> {

    return this.deleteCampCategoryRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>) => r.body as CampCategoryShowResponse2)
    );
  }

  /**
   * Path part for operation patchCampCategoryUpdate
   */
  static readonly PatchCampCategoryUpdatePath = '/v1/camp-category/{name}';

  /**
   * update a category.
   *
   * update category and set new image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampCategoryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryUpdate$Response(params: {
    name: string;
    body: CampCategoryDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampCategoryShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampCategoryService.PatchCampCategoryUpdatePath, 'patch');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampCategoryShowResponse2>;
      })
    );
  }

  /**
   * update a category.
   *
   * update category and set new image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampCategoryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampCategoryUpdate(params: {
    name: string;
    body: CampCategoryDto2
  },
  context?: HttpContext

): Observable<CampCategoryShowResponse2> {

    return this.patchCampCategoryUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampCategoryShowResponse2>) => r.body as CampCategoryShowResponse2)
    );
  }

}
