/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderCreateRequestDto } from '../models/order-create-request-dto';
import { OrderPaginateResponse } from '../models/order-paginate-response';
import { OrderShowResponse } from '../models/order-show-response';
import { OrderStoreResponse } from '../models/order-store-response';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrderIndex
   */
  static readonly GetOrderIndexPath = '/v1/order';

  /**
   * list orders.
   *
   * Fetch paginated list of orders
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'bookingCode';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'bookingCode';

    /**
     * Filter by bookingCode
     */
    bookingCode?: string;

    /**
     * Filter by customer email
     */
    email?: string;

    /**
     * Filter by customer-firstname
     */
    customerFirstname?: string;

    /**
     * Filter by customer-lastname
     */
    customerLastname?: string;

    /**
     * Filter by child-firstname
     */
    childFirstname?: string;

    /**
     * Filter by child-lastname
     */
    childLastname?: string;

    /**
     * Filter by trip id
     */
    trip?: number;

    /**
     * Filter by camp id
     */
    camp?: number;

    /**
     * Filter by program id
     */
    program?: number;

    /**
     * Filter by programAdditional id
     */
    programAdditional?: number;

    /**
     * Filter by departureLocation label
     */
    departureLocation?: string;

    /**
     * Filter by returnLocation label
     */
    returnLocation?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('bookingCode', params.bookingCode, {});
      rb.query('email', params.email, {});
      rb.query('customerFirstname', params.customerFirstname, {});
      rb.query('customerLastname', params.customerLastname, {});
      rb.query('childFirstname', params.childFirstname, {});
      rb.query('childLastname', params.childLastname, {});
      rb.query('trip', params.trip, {});
      rb.query('camp', params.camp, {});
      rb.query('program', params.program, {});
      rb.query('programAdditional', params.programAdditional, {});
      rb.query('departureLocation', params.departureLocation, {});
      rb.query('returnLocation', params.returnLocation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderPaginateResponse>;
      })
    );
  }

  /**
   * list orders.
   *
   * Fetch paginated list of orders
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'bookingCode';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'bookingCode';

    /**
     * Filter by bookingCode
     */
    bookingCode?: string;

    /**
     * Filter by customer email
     */
    email?: string;

    /**
     * Filter by customer-firstname
     */
    customerFirstname?: string;

    /**
     * Filter by customer-lastname
     */
    customerLastname?: string;

    /**
     * Filter by child-firstname
     */
    childFirstname?: string;

    /**
     * Filter by child-lastname
     */
    childLastname?: string;

    /**
     * Filter by trip id
     */
    trip?: number;

    /**
     * Filter by camp id
     */
    camp?: number;

    /**
     * Filter by program id
     */
    program?: number;

    /**
     * Filter by programAdditional id
     */
    programAdditional?: number;

    /**
     * Filter by departureLocation label
     */
    departureLocation?: string;

    /**
     * Filter by returnLocation label
     */
    returnLocation?: string;
  },
  context?: HttpContext

): Observable<OrderPaginateResponse> {

    return this.getOrderIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderPaginateResponse>) => r.body as OrderPaginateResponse)
    );
  }

  /**
   * Path part for operation postOrderCreate
   */
  static readonly PostOrderCreatePath = '/v1/order';

  /**
   * create multiple orders.
   *
   * Create one order per child and send out system emails if dryRun=false
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCreate$Response(params: {
    body: OrderCreateRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderStoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PostOrderCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderStoreResponse>;
      })
    );
  }

  /**
   * create multiple orders.
   *
   * Create one order per child and send out system emails if dryRun=false
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postOrderCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCreate(params: {
    body: OrderCreateRequestDto
  },
  context?: HttpContext

): Observable<OrderStoreResponse> {

    return this.postOrderCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderStoreResponse>) => r.body as OrderStoreResponse)
    );
  }

  /**
   * Path part for operation getOrderShow
   */
  static readonly GetOrderShowPath = '/v1/order/{id}';

  /**
   * show an order.
   *
   * Fetch a single detailed order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderShowResponse>;
      })
    );
  }

  /**
   * show an order.
   *
   * Fetch a single detailed order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<OrderShowResponse> {

    return this.getOrderShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderShowResponse>) => r.body as OrderShowResponse)
    );
  }

  /**
   * Path part for operation deleteOrderRemove
   */
  static readonly DeleteOrderRemovePath = '/v1/order/{id}';

  /**
   * remove an order.
   *
   * Mark an order as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrderShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.DeleteOrderRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderShowResponse>;
      })
    );
  }

  /**
   * remove an order.
   *
   * Mark an order as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrderRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<OrderShowResponse> {

    return this.deleteOrderRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderShowResponse>) => r.body as OrderShowResponse)
    );
  }

}
