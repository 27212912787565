/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampProgramDto } from '../models/camp-program-dto';
import { CampProgramDto2 } from '../models/camp-program-dto-2';
import { CampProgramPaginateResponse } from '../models/camp-program-paginate-response';
import { CampProgramShowResponse } from '../models/camp-program-show-response';
import { CampProgramShowResponse2 } from '../models/camp-program-show-response-2';

@Injectable({
  providedIn: 'root',
})
export class CampProgramService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampProgramIndex
   */
  static readonly GetCampProgramIndexPath = '/v1/camp-program';

  /**
   * list programs.
   *
   * Fetch a paginated list of camp programs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampProgramIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'type' | 'name' | 'label' | 'price' | 'description' | 'descriptionShort' | 'descriptionTitle' | 'descriptionMeta' | 'descriptionLink';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'type' | 'name' | 'label' | 'price' | 'description' | 'descriptionShort' | 'descriptionTitle' | 'descriptionMeta' | 'descriptionLink';

    /**
     * Filter program id or list of program ids comma separated
     */
    id?: string;

    /**
     * Filter by type
     */
    type?: 'standard' | 'zusatz';

    /**
     * Filter by name
     */
    name?: string;

    /**
     * Filter by camp id
     */
    camp?: number;

    /**
     * Filter by trip id
     */
    trip?: number;

    /**
     * Filter by program label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampProgramPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampProgramService.GetCampProgramIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('name', params.name, {});
      rb.query('camp', params.camp, {});
      rb.query('trip', params.trip, {});
      rb.query('label', params.label, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampProgramPaginateResponse>;
      })
    );
  }

  /**
   * list programs.
   *
   * Fetch a paginated list of camp programs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampProgramIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id' | 'type' | 'name' | 'label' | 'price' | 'description' | 'descriptionShort' | 'descriptionTitle' | 'descriptionMeta' | 'descriptionLink';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id' | 'type' | 'name' | 'label' | 'price' | 'description' | 'descriptionShort' | 'descriptionTitle' | 'descriptionMeta' | 'descriptionLink';

    /**
     * Filter program id or list of program ids comma separated
     */
    id?: string;

    /**
     * Filter by type
     */
    type?: 'standard' | 'zusatz';

    /**
     * Filter by name
     */
    name?: string;

    /**
     * Filter by camp id
     */
    camp?: number;

    /**
     * Filter by trip id
     */
    trip?: number;

    /**
     * Filter by program label
     */
    label?: string;
  },
  context?: HttpContext

): Observable<CampProgramPaginateResponse> {

    return this.getCampProgramIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampProgramPaginateResponse>) => r.body as CampProgramPaginateResponse)
    );
  }

  /**
   * Path part for operation postCampProgramCreate
   */
  static readonly PostCampProgramCreatePath = '/v1/camp-program';

  /**
   * add a new program.
   *
   * create a new program connected to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampProgramCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampProgramCreate$Response(params: {
    body: CampProgramDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampProgramShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampProgramService.PostCampProgramCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampProgramShowResponse>;
      })
    );
  }

  /**
   * add a new program.
   *
   * create a new program connected to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampProgramCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampProgramCreate(params: {
    body: CampProgramDto
  },
  context?: HttpContext

): Observable<CampProgramShowResponse> {

    return this.postCampProgramCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse>) => r.body as CampProgramShowResponse)
    );
  }

  /**
   * Path part for operation getCampProgramShow
   */
  static readonly GetCampProgramShowPath = '/v1/camp-program/{name}';

  /**
   * show a program.
   *
   * Fetch a single program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampProgramShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramShow$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampProgramShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampProgramService.GetCampProgramShowPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampProgramShowResponse2>;
      })
    );
  }

  /**
   * show a program.
   *
   * Fetch a single program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampProgramShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampProgramShow(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampProgramShowResponse2> {

    return this.getCampProgramShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>) => r.body as CampProgramShowResponse2)
    );
  }

  /**
   * Path part for operation deleteCampProgramRemove
   */
  static readonly DeleteCampProgramRemovePath = '/v1/camp-program/{name}';

  /**
   * remove a program.
   *
   * Mark program as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampProgramRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampProgramRemove$Response(params: {
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampProgramShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampProgramService.DeleteCampProgramRemovePath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampProgramShowResponse2>;
      })
    );
  }

  /**
   * remove a program.
   *
   * Mark program as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampProgramRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampProgramRemove(params: {
    name: string;
  },
  context?: HttpContext

): Observable<CampProgramShowResponse2> {

    return this.deleteCampProgramRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>) => r.body as CampProgramShowResponse2)
    );
  }

  /**
   * Path part for operation patchCampProgramUpdate
   */
  static readonly PatchCampProgramUpdatePath = '/v1/camp-program/{name}';

  /**
   * update a program.
   *
   * update a programs data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampProgramUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampProgramUpdate$Response(params: {
    name: string;
    body: CampProgramDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampProgramShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CampProgramService.PatchCampProgramUpdatePath, 'patch');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampProgramShowResponse2>;
      })
    );
  }

  /**
   * update a program.
   *
   * update a programs data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampProgramUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampProgramUpdate(params: {
    name: string;
    body: CampProgramDto2
  },
  context?: HttpContext

): Observable<CampProgramShowResponse2> {

    return this.patchCampProgramUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampProgramShowResponse2>) => r.body as CampProgramShowResponse2)
    );
  }

}
