// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  display: inline-block;
}
.logo-container .app-version-indicator {
  font-size: 10px;
  text-align: right;
  color: rgba(0, 0, 0, 0.37);
}
.logo-container .logo-maennchen-headshot {
  border-radius: 100%;
}
.logo-container .logo-nur-schrift {
  margin-left: 3px;
}
@media (max-width: 665px) {
  .logo-container .logo-nur-schrift.show-md {
    display: none;
  }
}

html.nb-dark-theme app-navigation ion-title a {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/module/shared/component/layout/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,0BAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIM;EADF;IAEI,aAAA;EADN;AACF;;AAQI;EACE,WAAA;AALN","sourcesContent":[".logo-container {\n  display: inline-block;\n\n  .app-version-indicator {\n    font-size: 10px;\n    text-align: right;\n    color: rgba(0, 0, 0, 0.37);\n  }\n\n  .logo-maennchen-headshot {\n    border-radius: 100%;\n  }\n\n  .logo-nur-schrift {\n    margin-left: 3px;\n\n    &.show-md {\n      @media (max-width: 665px) {\n        display: none;\n      }\n    }\n  }\n}\n\nhtml.nb-dark-theme {\n  app-navigation {\n    ion-title a {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
