import {
  APP_INITIALIZER,
  forwardRef,
  NgModule,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './module/shared/interceptor/token.interceptor';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ApiModule as KidflApiModule } from '@kidfl/openapi';
import { environment } from '../environments/environment';
import { BartComponent } from './module/shared/component/layout/bart/bart.component';
import { SidebarComponent } from './module/shared/component/layout/sidebar/sidebar.component';
import { AuthErrorService } from './module/shared/service/auth-error.service';
import { routes } from './app-routes';
import { RichTextEditorComponent } from './module/shared/component/rich-text-editor/rich-text-editor.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { HttpEventInterceptor } from './module/shared/interceptor/http-event.interceptor';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    KidflApiModule.forRoot({
      rootUrl: environment.apiUrl,
    }),
    BartComponent,
    SidebarComponent,
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, {
      ...withComponentInputBinding(),
    }),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpEventInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authErrorService: AuthErrorService) => () => authErrorService.init(),
      deps: [AuthErrorService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
