import { HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpEventService {
  private event$ = new EventEmitter<{ res: HttpResponse<unknown>; req: HttpRequest<unknown> }>();

  private getSuccessfulWriteOperations(pathmatch: RegExp) {
    return toSignal(
      this.event$.pipe(
        filter((e) => ['PUT', 'PATCH', 'POST', 'DELETE'].includes(e.req.method)),
        filter((e) => e.res.ok),
        filter((e) => pathmatch.test(new URL(e.req.url).pathname)),
        tap(() => console.log('Write event registered for', pathmatch)),
      ),
    );
  }

  writeBlog = this.getSuccessfulWriteOperations(/^\/v1\/blog/);
  writeCamp = this.getSuccessfulWriteOperations(/^\/v1\/camp/);
  writeCampCategory = this.getSuccessfulWriteOperations(/^\/v1\/category\/[a-z-]+\/camp/);
  writeCampProgram = this.getSuccessfulWriteOperations(/^\/v1\/camp-program/);
  writeCategory = this.getSuccessfulWriteOperations(/^\/v1\/camp-category/);
  writeDiscount = this.getSuccessfulWriteOperations(/^\/v1\/discount/);
}
