/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampDto3 } from '../models/camp-dto-3';
import { CampTripDto } from '../models/camp-trip-dto';
import { CampTripDto2 } from '../models/camp-trip-dto-2';
import { CampTripPaginateResponse } from '../models/camp-trip-paginate-response';
import { CampTripShowResponse } from '../models/camp-trip-show-response';
import { TripProgramDto } from '../models/trip-program-dto';

@Injectable({
  providedIn: 'root',
})
export class CampTripService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampTripIndex
   */
  static readonly GetCampTripIndexPath = '/v1/camp-trip';

  /**
   * list trips.
   *
   * Fetch paginated list of camp trips
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field (array possible)
     */
    'orderBy[]'?: Array<'start:asc' | 'start:desc' | 'end:asc' | 'end:desc'>;

    /**
     * Filter trip id
     */
    id?: number;

    /**
     * Filter trip start
     */
    start?: string;

    /**
     * Filter trip end
     */
    end?: string;

    /**
     * Filter camp id
     */
    camp?: number;

    /**
     * Filter by trip state. Comma separated allowed. <br>0 => AVAILABLE<br>1 => FEW_SPACES_LEFT<br>2 => SOLD_OUT<br>3 => ONLY_PHONE
     */
    state?: string;

    /**
     * Filter departure location id
     */
    departureLocation?: string;

    /**
     * Filter return location id
     */
    returnLocation?: string;

    /**
     * Filter program id
     */
    program?: string;

    /**
     * Filter by program ids comma separated
     */
    additionalProgram?: string;

    /**
     * Filter by bookingCode
     */
    bookingCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.GetCampTripIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderBy[]', params['orderBy[]'], {});
      rb.query('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('camp', params.camp, {});
      rb.query('state', params.state, {});
      rb.query('departureLocation', params.departureLocation, {});
      rb.query('returnLocation', params.returnLocation, {});
      rb.query('program', params.program, {});
      rb.query('additionalProgram', params.additionalProgram, {});
      rb.query('bookingCode', params.bookingCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripPaginateResponse>;
      })
    );
  }

  /**
   * list trips.
   *
   * Fetch paginated list of camp trips
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field (array possible)
     */
    'orderBy[]'?: Array<'start:asc' | 'start:desc' | 'end:asc' | 'end:desc'>;

    /**
     * Filter trip id
     */
    id?: number;

    /**
     * Filter trip start
     */
    start?: string;

    /**
     * Filter trip end
     */
    end?: string;

    /**
     * Filter camp id
     */
    camp?: number;

    /**
     * Filter by trip state. Comma separated allowed. <br>0 => AVAILABLE<br>1 => FEW_SPACES_LEFT<br>2 => SOLD_OUT<br>3 => ONLY_PHONE
     */
    state?: string;

    /**
     * Filter departure location id
     */
    departureLocation?: string;

    /**
     * Filter return location id
     */
    returnLocation?: string;

    /**
     * Filter program id
     */
    program?: string;

    /**
     * Filter by program ids comma separated
     */
    additionalProgram?: string;

    /**
     * Filter by bookingCode
     */
    bookingCode?: string;
  },
  context?: HttpContext

): Observable<CampTripPaginateResponse> {

    return this.getCampTripIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripPaginateResponse>) => r.body as CampTripPaginateResponse)
    );
  }

  /**
   * Path part for operation postCampTripCreate
   */
  static readonly PostCampTripCreatePath = '/v1/camp-trip';

  /**
   * create trip.
   *
   * Create a new camp-trip and connect it to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCreate$Response(params: {
    body: CampTripDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.PostCampTripCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripShowResponse>;
      })
    );
  }

  /**
   * create trip.
   *
   * Create a new camp-trip and connect it to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCreate(params: {
    body: CampTripDto
  },
  context?: HttpContext

): Observable<CampTripShowResponse> {

    return this.postCampTripCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>) => r.body as CampTripShowResponse)
    );
  }

  /**
   * Path part for operation getCampTripShow
   */
  static readonly GetCampTripShowPath = '/v1/camp-trip/{id}';

  /**
   * show a trip.
   *
   * Fetch a single camp-trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.GetCampTripShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripShowResponse>;
      })
    );
  }

  /**
   * show a trip.
   *
   * Fetch a single camp-trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CampTripShowResponse> {

    return this.getCampTripShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>) => r.body as CampTripShowResponse)
    );
  }

  /**
   * Path part for operation deleteCampTripRemove
   */
  static readonly DeleteCampTripRemovePath = '/v1/camp-trip/{id}';

  /**
   * remove trip.
   *
   * Mark trip as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampTripRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.DeleteCampTripRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripShowResponse>;
      })
    );
  }

  /**
   * remove trip.
   *
   * Mark trip as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampTripRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CampTripShowResponse> {

    return this.deleteCampTripRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>) => r.body as CampTripShowResponse)
    );
  }

  /**
   * Path part for operation patchCampTripUpdate
   */
  static readonly PatchCampTripUpdatePath = '/v1/camp-trip/{id}';

  /**
   * update a trip.
   *
   * update a trip but not the camp or other entities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampTripUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripUpdate$Response(params: {
    id: string;
    body: CampTripDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.PatchCampTripUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripShowResponse>;
      })
    );
  }

  /**
   * update a trip.
   *
   * update a trip but not the camp or other entities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampTripUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripUpdate(params: {
    id: string;
    body: CampTripDto2
  },
  context?: HttpContext

): Observable<CampTripShowResponse> {

    return this.patchCampTripUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>) => r.body as CampTripShowResponse)
    );
  }

  /**
   * Path part for operation postCampTripCopy
   */
  static readonly PostCampTripCopyPath = '/v1/camp-trip/{id}/copy';

  /**
   * copy trip.
   *
   * copy a trip to another camp (including locations)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripCopy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCopy$Response(params: {
    id: string;
    body: CampDto3
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CampTripShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.PostCampTripCopyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampTripShowResponse>;
      })
    );
  }

  /**
   * copy trip.
   *
   * copy a trip to another camp (including locations)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripCopy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCopy(params: {
    id: string;
    body: CampDto3
  },
  context?: HttpContext

): Observable<CampTripShowResponse> {

    return this.postCampTripCopy$Response(params,context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>) => r.body as CampTripShowResponse)
    );
  }

  /**
   * Path part for operation postTripProgramCreate
   */
  static readonly PostTripProgramCreatePath = '/v1/camp-trip/{trip}/program';

  /**
   * add a program to a trip.
   *
   * Connect an existing program to an exisiting trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTripProgramCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTripProgramCreate$Response(params: {
    trip: string;
    body: TripProgramDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.PostTripProgramCreatePath, 'post');
    if (params) {
      rb.path('trip', params.trip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * add a program to a trip.
   *
   * Connect an existing program to an exisiting trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postTripProgramCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTripProgramCreate(params: {
    trip: string;
    body: TripProgramDto
  },
  context?: HttpContext

): Observable<void> {

    return this.postTripProgramCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteTripProgramRemove
   */
  static readonly DeleteTripProgramRemovePath = '/v1/camp-trip/{trip}/program/{program}';

  /**
   * remove a program from a trip.
   *
   * Disconnect an existing program from an exisiting trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTripProgramRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTripProgramRemove$Response(params: {
    trip: string;
    program: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampTripService.DeleteTripProgramRemovePath, 'delete');
    if (params) {
      rb.path('trip', params.trip, {});
      rb.path('program', params.program, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * remove a program from a trip.
   *
   * Disconnect an existing program from an exisiting trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTripProgramRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTripProgramRemove(params: {
    trip: string;
    program: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteTripProgramRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
