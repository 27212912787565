/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerDto } from '../models/customer-dto';
import { CustomerDto2 } from '../models/customer-dto-2';
import { CustomerPaginateResponse } from '../models/customer-paginate-response';
import { CustomerShowResponse } from '../models/customer-show-response';
import { CustomerShowResponse2 } from '../models/customer-show-response-2';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerIndex
   */
  static readonly GetCustomerIndexPath = '/v1/customer';

  /**
   * list customers.
   *
   * Fetch a paginated list of customers with addresses + contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerIndex$Response(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id';

    /**
     * Filter customer id
     */
    id?: number;

    /**
     * Filter customer person.firstname
     */
    firstname?: string;

    /**
     * Filter customer person.lastname
     */
    lastname?: string;

    /**
     * Filter customer email address
     */
    email?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomerPaginateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerIndexPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderAsc', params.orderAsc, {});
      rb.query('orderDesc', params.orderDesc, {});
      rb.query('id', params.id, {});
      rb.query('firstname', params.firstname, {});
      rb.query('lastname', params.lastname, {});
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerPaginateResponse>;
      })
    );
  }

  /**
   * list customers.
   *
   * Fetch a paginated list of customers with addresses + contacts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerIndex(params?: {

    /**
     * Pagination offset
     */
    page?: number;

    /**
     * Pagination limit
     */
    limit?: number;

    /**
     * Pagination order field ascending
     */
    orderAsc?: 'id';

    /**
     * Pagination order field descending
     */
    orderDesc?: 'id';

    /**
     * Filter customer id
     */
    id?: number;

    /**
     * Filter customer person.firstname
     */
    firstname?: string;

    /**
     * Filter customer person.lastname
     */
    lastname?: string;

    /**
     * Filter customer email address
     */
    email?: string;
  },
  context?: HttpContext

): Observable<CustomerPaginateResponse> {

    return this.getCustomerIndex$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomerPaginateResponse>) => r.body as CustomerPaginateResponse)
    );
  }

  /**
   * Path part for operation postCustomerCreate
   */
  static readonly PostCustomerCreatePath = '/v1/customer';

  /**
   * add a new customer.
   *
   * create a new customer with addresses and contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCreate$Response(params: {
    body: CustomerDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomerShowResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerShowResponse>;
      })
    );
  }

  /**
   * add a new customer.
   *
   * create a new customer with addresses and contacts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCustomerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCreate(params: {
    body: CustomerDto
  },
  context?: HttpContext

): Observable<CustomerShowResponse> {

    return this.postCustomerCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse>) => r.body as CustomerShowResponse)
    );
  }

  /**
   * Path part for operation getCustomerShow
   */
  static readonly GetCustomerShowPath = '/v1/customer/{id}';

  /**
   * show a customer.
   *
   * Fetch a single customer with contacts and addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerShow$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomerShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerShowPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerShowResponse2>;
      })
    );
  }

  /**
   * show a customer.
   *
   * Fetch a single customer with contacts and addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerShow(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CustomerShowResponse2> {

    return this.getCustomerShow$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>) => r.body as CustomerShowResponse2)
    );
  }

  /**
   * Path part for operation deleteCustomerRemove
   */
  static readonly DeleteCustomerRemovePath = '/v1/customer/{id}';

  /**
   * remove a customer.
   *
   * Mark customer as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerRemove$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomerShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerShowResponse2>;
      })
    );
  }

  /**
   * remove a customer.
   *
   * Mark customer as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerRemove(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CustomerShowResponse2> {

    return this.deleteCustomerRemove$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>) => r.body as CustomerShowResponse2)
    );
  }

  /**
   * Path part for operation patchCustomerUpdate
   */
  static readonly PatchCustomerUpdatePath = '/v1/customer/{id}';

  /**
   * update a customer.
   *
   * update customer, remove all addresses & contacts and replace with the given ones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerUpdate$Response(params: {
    id: string;
    body: CustomerDto2
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomerShowResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PatchCustomerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerShowResponse2>;
      })
    );
  }

  /**
   * update a customer.
   *
   * update customer, remove all addresses & contacts and replace with the given ones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCustomerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerUpdate(params: {
    id: string;
    body: CustomerDto2
  },
  context?: HttpContext

): Observable<CustomerShowResponse2> {

    return this.patchCustomerUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomerShowResponse2>) => r.body as CustomerShowResponse2)
    );
  }

}
